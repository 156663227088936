import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

export default function noteTemplate({ data }) {
  console.log('data', data)

  const note = data.markdownRemark;

  console.log('note', note)

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={note.frontmatter.title}
      />

      <article className="note-container">
        <h1 className="heading text-5xl">{note.frontmatter.title}</h1>
        <h2>{note.frontmatter.date}</h2>
        <div
          className="note-content"
          dangerouslySetInnerHTML={{ __html: note.html }}
        />
      </article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      frontmatter {
        date(formatString: "DD MM, YYYY")
        path
        title
      }
      html
    }
  }
`;
